import React, {Component} from 'react';

class BarraSVG extends Component {
  constructor(){
    super();
    this.state={
      mobil:false,
    }
  }

  handleResize = () => {
    console.log(this.state.mobil);

    var width = window.innerWidth|| document.documentElement.clientWidth|| document.body.clientWidth;
    var height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
    if (width <= 502) {
      this.setState({mobil:true})
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

render (){
     return (
       <div className="BarraFortuna">
         <svg  id="BarraFortuna" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"viewBox="-350 0 3500 2000">

          <style>{`.BarraFortuna .st0{fill:none;stroke:#000000;stroke-width:2;stroke-miterlimit:10;} .BarraFortuna .st1{fill:#FFFFFF;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}`}</style>
          <g id="UMtFyd_1_">
            <g id="XMLID_1_">
              <path id="XMLID_85_" class="st0" d="M488.6,1769.3c-5.9,15-9.9,30.5-13.7,46.1c-4.7,19.3-14.6,34-35.7,40.9c-6.4,0-12.7,0-19.1,0
                c-31.3-14.3-38.1-28.8-28.5-60c3.6-11.5,6.9-23.1,10.8-36.3c-8,0-13.7,0-19.5,0c-86.2,0-172.4-0.7-258.6,0.4
                c-26.9,0.4-46-6.7-56.9-29.8c0-6.8,0-13.6,0-20.5c10.6-22.5,29.5-31.2,55.6-29.6c16.1,1,32.3,0.2,48.4,0.2
                c0-199.5,0-396.4,0-595.3c-17,0-33.3-0.8-49.5,0.2c-26.4,1.6-45.1-7.2-54.4-30.7c0-6.8,0-13.6,0-20.5c12-22.8,32.6-28.5,58.6-27.9
                c43.9,1.1,87.8,0.3,131.8,0.3c5.7,0,11.5,0,19.2,0c0-18.8-0.1-36.2,0-53.5c0.1-25.6,16.2-41.8,41.6-42.2c25.9-0.4,43.4,16,44,41.9
                c0.4,17,0.1,34,0.1,52.4c36,0,71,0,105.9,0c0-7.4-1.4-15.3,0.3-22.5c4.3-19,20.6-30.9,42.2-31c48.2-0.3,96.3-0.4,144.5,0
                c30.5,0.3,45.9,20.2,41.5,54.8c6.7,0,13.3,0,20,0c351.4,0,1533.8,0.8,1885.2,0.3c26.7,0,46.3,6.8,56.2,30.8c0,5.8,0,11.7,0,17.5
                c-9.9,24-29.3,32.4-56.1,30.7c-15.6-1-31.4-0.2-47.5-0.2c0,199.3,0,396.6,0,595.3c15.7,0,30.5,0.9,45.1-0.2
                c27-2.1,47.1,6.2,58.6,29.6c0,6.8,0,13.6,0,20.5c-11.3,22.5-30,30.2-57,29.9c-86.1-1.2-172.3-0.4-258.5-0.4c-5.7,0-11.5,0-19.4,0
                c3.7,12.7,6.8,23.9,10.3,34.9c10.1,32.8,3.9,46.6-28,61.4c-6.4,0-12.7,0-19.1,0c-20-6.9-30.9-20.2-35.4-39.4
                c-3.8-16.1-9.2-31.9-15.2-47.5c-1.5-4-8-9-12.2-9.1c-72.5-0.6-144.9-0.6-217.4,0c-4.3,0-10.9,4.9-12.5,8.8
                c-5.8,14.6-10.9,29.5-14.4,44.7c-4.6,19.9-14.6,35-36,42.5c-6.4,0-12.7,0-19.1,0c-31.4-14.2-38.3-28.8-28.6-59.9
                c3.6-11.5,6.8-23.2,10.5-35.8c-85.9,0-1000.9-0.6-1087-0.6c3.5,12.1,6.7,23.2,10.1,34.3c10.1,32.8,3.8,46.7-28.2,61.4
                c-6.4,0-12.7,0-19.1,0c-20.8-7.1-31.1-21.5-35.6-41c-3.6-15.6-8.8-31-14.7-46.1c-1.5-3.9-8.2-8.8-12.5-8.8
                c-72.5-0.5-155.2-0.6-227.7-0.1 M2013.6,1411.5c-2.3-0.7-3.3-1.3-4.3-1.3c-24.9-0.1-49.9-1.6-74.6,0.1
                c-28.6,2-49.2-21.1-48.5-44.4c1.3-44.8,0.4-89.7,0.4-134.5c0-29.8,16.2-45.5,48.4-45.5c120.2-0.2,240.3-0.2,360.5,0
                c30.8,0,48.2,16.4,48.3,44.6c0.2,45.3,0.2,90.7,0,136c-0.1,27-18.2,43.4-47.5,43.5c-26.2,0.1-52.4,0-78.3,0
                c-0.3,2.9-0.7,3.9-0.4,4.8c26.2,86.1,52.6,172.1,78.5,258.2c2.6,8.5,7.8,8.8,15.1,8.7c42.9-0.1,85.7-0.1,128.6-0.1
                c8.8,0,17.7,0,27.2,0c0-198.9,0-396.1,0-594.3c-459.2,0-1748.8-0.6-2207.5-0.6c0,198.5,0,396,0,594.3c52.1,0,102.9-1.1,153.6,0.3
                c1.7,0,14-1.7,14.2-2.3c1.6-4.7,2.9-7.7,3.9-10.9c20.7-69.3,42.2-138.5,63.3-207.7c5.1-16.6,9.8-33.2,14.8-49.8
                c-3-0.7-4-1.2-5-1.2c-24.3-0.1-48.7-0.1-73-0.2c-31.8-0.1-49-15.7-49.1-44.9c-0.1-44.8-0.1-89.7,0-134.5
                c0-28.4,16.9-44.6,47.9-44.7c120.7-0.2,241.4-0.2,362.1,0c30,0.1,47.1,16.3,47.2,43.9c0.2,45.8,0.2,91.6,0,137.4
                c-0.1,26.4-18,42.6-46.6,42.7c-26.4,0.1-52.7,0-79.9,0c0.6,3.7,0.7,6.1,1.4,8.4c25.8,84.7,51.9,169.2,77.3,254
                c2.8,9.2,8.4,9.4,16.2,9.4c93.2-0.1,1017.3,0.4,1110.5,0.8c10.6,0,14-3.4,16.7-12.5c15.3-52,31.6-103.8,47.5-155.7
                C1992.9,1479.5,2003.3,1445.4,2013.6,1411.5z M2020.4,1680.3c64.3,0,125.9,0,187.9,0c-0.4-2.9-0.5-4.8-1.1-6.7
                c-25.9-85.1-52-170.1-77.6-255.3c-2.8-9.2-9-8.4-16.5-9c-9.4-0.8-11.6,4.3-13.5,10.8c-15,49.6-30.1,99.1-45.2,148.7
                C2043.2,1605.7,2031.9,1642.6,2020.4,1680.3z M704.5,1680.1c-1.3-5.4-2.1-10.2-3.5-14.8c-24.8-81.3-49.8-162.6-74.5-244
                c-2.3-7.5-4.2-12.7-15.1-12.8c-11.4-0.2-14.1,4.9-16.5,13.1c-17.9,59.4-36.1,118.8-54.2,178.1c-8.1,26.5-16.1,53-24.5,80.4
                C580.1,1680.1,641.8,1680.1,704.5,1680.1z M1972.9,1329.2c95.6,0,189.9,0,283.7,0c0-21.9,0-42.6,0-63.1c-95.2,0-189.1,0-283.7,0
                C1972.9,1287.5,1972.9,1307.8,1972.9,1329.2z M469,1265.3c0,22.2,0,42.9,0,63.7c95.1,0,189,0,283.6,0c0-21.5,0-42.2,0-63.7
                C657.8,1265.3,563.9,1265.3,469,1265.3z"/>
              <path id="XMLID_26_" class="st0" d="M304.2,377.2"/>
              <path id="XMLID_32_" class="st0" d="M351,572.4h135.2l32.7-21.4L463,424.8l-50.1-56l-35.4-17.3l0-4.4
                c-0.6-86.8-0.8-166.4-0.7-253.2c0-4.2,0.4-8.5,0.7-13c145.8,0,1238.9,16.1,1384.4,16.1l86.7,0.2c145.9,0,290.6,0,436,0l2.9,0.2"/>
              <path id="XMLID_30_" class="st0" d="M2299.1,587.4h135.2L2467,566l-55.8-126.2l-50.1-56l-35.4-17.3l0-4.4
                c-0.6-86.8-0.8-166.4-0.7-253.2l0-12.6l45.7-0.3c7.3,0,13,0,18.7,0c79.4,0,158.8-0.8,238.2,0.5c27,0.4,45.8-7.4,57.3-29.7
                c0-5.8,0-11.7,0-17.5c-6.1-15.9-17-27.2-34.9-32.2C2086.5,17.1,599.3,1,35.9,1C19.2,5.6,8.4,16.2,1,30.2c0,6.8,0,13.6,0,20.5
                c11,21.8,29,30,55.6,29.6c80.5-1.2,160.9-0.5,241.4-0.4c5.5,0,35.2,0.4,41.3,0.7c0,91.5-0.2,181.2,0.3,270.9l-35.4,17.3l-50.1,56
                l-55.8,126.2l32.7,21.4h135.2"/>
            </g>
          </g>
          <path id="XMLID_2_" class="st0" d="M1792.3,1007.4"/>
          <line id="XMLID_4_" class="st0" x1="479.1" y1="1799.9" x2="517.5" y2="1675.8"/>
          <line id="XMLID_5_" class="st0" x1="394" y1="1788.2" x2="434.8" y2="1655.1"/>
          <path id="XMLID_6_" class="st0" d="M424.4,1678.6"/>
          <line id="XMLID_8_" class="st0" x1="743.1" y1="1801.8" x2="697.5" y2="1653.6"/>
          <line id="XMLID_9_" class="st0" x1="824.5" y1="1778.3" x2="776.7" y2="1622.3"/>
          <line id="XMLID_10_" class="st0" x1="1902.2" y1="1775.4" x2="1941.1" y2="1648.8"/>
          <line id="XMLID_11_" class="st0" x1="1986" y1="1791.4" x2="2037.7" y2="1623.5"/>
          <line id="XMLID_12_" class="st0" x1="2244.3" y1="1793.2" x2="2203.3" y2="1660.7"/>
          <line id="XMLID_13_" class="st0" x1="2292.3" y1="1660.7" x2="2327.7" y2="1775.4"/>
          <path id="XMLID_7_" class="st0" d="M1505.6,1144.3h-186c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h186c6.6,0,12,5.4,12,12v6
            C1517.6,1138.9,1512.2,1144.3,1505.6,1144.3z"/>
          <path id="XMLID_14_" class="st0" d="M1562.3,1190.3h-104.7c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h104.7c6.6,0,12,5.4,12,12v6
            C1574.3,1184.9,1568.9,1190.3,1562.3,1190.3z"/>
          <path id="XMLID_15_" class="st0" d="M1385.3,1190.3h-104.7c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h104.7c6.6,0,12,5.4,12,12v6
            C1397.3,1184.9,1391.9,1190.3,1385.3,1190.3z"/>
          <path id="XMLID_18_" class="st0" d="M1863,1498.3h-186c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h186c6.6,0,12,5.4,12,12v6
            C1875,1492.9,1869.6,1498.3,1863,1498.3z"/>
          <path id="XMLID_17_" class="st0" d="M1919.6,1544.3H1815c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h104.7c6.6,0,12,5.4,12,12v6
            C1931.6,1538.9,1926.2,1544.3,1919.6,1544.3z"/>
          <path id="XMLID_16_" class="st0" d="M1742.6,1544.3H1638c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h104.7c6.6,0,12,5.4,12,12v6
            C1754.6,1538.9,1749.2,1544.3,1742.6,1544.3z"/>
          <path id="XMLID_21_" class="st0" d="M1128,1283H942c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h186c6.6,0,12,5.4,12,12v6
            C1140,1277.6,1134.6,1283,1128,1283z"/>
          <path id="XMLID_20_" class="st0" d="M1184.6,1329H1080c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h104.7c6.6,0,12,5.4,12,12v6
            C1196.6,1323.6,1191.2,1329,1184.6,1329z"/>
          <path id="XMLID_19_" class="st0" d="M1007.6,1329H903c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h104.7c6.6,0,12,5.4,12,12v6
            C1019.6,1323.6,1014.2,1329,1007.6,1329z"/>
          <path id="XMLID_23_" class="st0" d="M2392.3,1521.3h-123.7c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h123.7c6.6,0,12,5.4,12,12v6
            C2404.3,1515.9,2398.9,1521.3,2392.3,1521.3z"/>
          <path id="XMLID_22_" class="st0" d="M2449,1567.3h-51.6c-6.6,0-12-5.4-12-12v-6c0-6.6,5.4-12,12-12h51.6c6.6,0,12,5.4,12,12v6
            C2461,1561.9,2455.6,1567.3,2449,1567.3z"/>
          <path id="XMLID_27_" class="st0" d="M412.9,377.2"/>
          <path id="XMLID_29_" class="st0" d="M2252.3,392.3"/>
          <path id="XMLID_28_" class="st0" d="M2314.2,587.4H2179l-32.7-21.4l55.8-126.2l50.1-56l35.4-17.3c-0.5-89.7-0.3-179.3-0.3-270.9"/>
          <path id="XMLID_25_" class="st0" d="M2361,392.3"/>
          <path id="XMLID_24_" class="st0" d="M1785.6,827.4H1009c-6.6,0-12-5.4-12-12v-486c0-6.6,5.4-12,12-12h776.7c6.6,0,12,5.4,12,12v486
            C1797.6,822,1792.2,827.4,1785.6,827.4z"/>
          <path id="XMLID_31_" class="st0" d="M1736.6,780h-668.8c-6.6,0-12-5.4-12-12V376.8c0-6.6,5.4-12,12-12h668.8c6.6,0,12,5.4,12,12V768
            C1748.6,774.6,1743.2,780,1736.6,780z"/>
          <path id="XMLID_110_" class="st0" d="M1237.4,657.7c-1.7,2.3-2.6,4.1-3.9,5.1c-6.2,4.6-6.5,12.3-0.6,16.9c1.7,1.4,3,3.3,5.2,5.9
            c1.1,0.2,3.4,0.8,3.3-3.1c0-0.7,1.1-1.5,1.9-2c7.7-5.7,7.7-12.4,0.1-18.1c-1.2-0.9-1.7-2.8-2.8-4.8c3.8-0.2,6.3-0.3,9.2-0.5
            c2.3-36.6,4.6-73,6.8-109.6c-12.2,0-23.6,0-35.6,0c2.3,36.7,4.6,73.1,6.9,109.6C1230.9,657.4,1233.4,657.5,1237.4,657.7z
             M1160.3,685.6c1.6-2.2,2.5-4.1,4-5.3c6.6-5.1,6.6-12.4,0-17.5c-1.3-1-1.9-2.9-3.5-5.5c4.1,0,6.6,0,9,0c0.1-1.1,0.3-1.8,0.3-2.4
            c1.9-29.5,3.9-59.1,5.8-88.6c0.7-10.7-6.6-18.8-16.7-18.9c-10.3-0.1-17.5,8.1-16.9,19.1c1.3,21.4,2.6,42.8,4,64.2
            c0.6,8.8,1.3,17.5,1.9,26.5c2.8,0.2,5.2,0.3,9.4,0.6c-1.9,2.4-2.9,4.2-4.4,5.4c-5.7,4.5-5.8,12.2,0,16.6c1.1,0.9,2.8,1.9,2.9,3
            C1156.6,686.7,1158.9,685.8,1160.3,685.6z"/>
          <path id="XMLID_95_" class="st0" d="M1244.9,651.5c-1,0.3-1.3,0.6-1.6,0.6c-12.2,0.1-10.5,1.9-11.2-10.1
            c-1.8-29.6-3.7-59.1-5.6-89.1c8.2,0,16.2,0,24.6,0C1249,585.8,1246.9,618.7,1244.9,651.5z"/>
          <path id="XMLID_94_" class="st0" d="M1240.1,665.9c1.2,2,3.6,4.2,3.2,5.9c-0.3,1.8-3.2,3.2-5,4.7c-1.5-1.7-4-3.3-4.1-5.1
            c0-1.9,2.3-3.8,3.6-5.7C1238.6,665.8,1239.3,665.8,1240.1,665.9z"/>
          <path id="XMLID_93_" class="st0" d="M1165.1,652c-4.1,0-7.7,0-11.9,0c-0.6-8.5-1.3-16.9-1.8-25.3c-1.3-20.6-2.6-41.2-3.7-61.8
            c-0.4-7,4.7-12.4,11.4-12.4c6.8,0,12,5.2,11.6,12.2c-1.2,21.1-2.6,42.2-3.9,63.3C1166.2,635.8,1165.7,643.6,1165.1,652z"/>
          <path id="XMLID_92_" class="st0" d="M1164.9,672.7c-2,1.3-4,3.6-5.9,3.5c-1.7-0.1-4.7-3.1-4.5-4.4c0.3-1.9,2.8-4.7,4.6-4.9
            c1.7-0.2,3.9,2.3,5.8,3.6C1164.9,671.2,1164.9,671.9,1164.9,672.7z"/>
          <path id="XMLID_36_" class="st0" d="M1314.2,657.7c-1.7,2.3-2.6,4.1-3.9,5.1c-6.2,4.6-6.5,12.3-0.6,16.9c1.7,1.4,3,3.3,5.2,5.9
            c1.1,0.2,3.4,0.8,3.3-3.1c0-0.7,1.1-1.5,1.9-2c7.7-5.7,7.7-12.4,0.1-18.1c-1.2-0.9-1.7-2.8-2.8-4.8c3.8-0.2,6.3-0.3,9.2-0.5
            c2.3-36.6,4.6-73,6.8-109.6c-12.2,0-23.6,0-35.6,0c2.3,36.7,4.6,73.1,6.9,109.6C1307.7,657.4,1310.2,657.5,1314.2,657.7z"/>
          <path id="XMLID_35_" class="st0" d="M1321.7,651.5c-1,0.3-1.3,0.6-1.6,0.6c-12.2,0.1-10.5,1.9-11.2-10.1
            c-1.8-29.6-3.7-59.1-5.6-89.1c8.2,0,16.2,0,24.6,0C1325.8,585.8,1323.7,618.7,1321.7,651.5z"/>
          <path id="XMLID_34_" class="st0" d="M1316.9,665.9c1.2,2,3.6,4.2,3.2,5.9c-0.3,1.8-3.2,3.2-5,4.7c-1.5-1.7-4-3.3-4.1-5.1
            c0-1.9,2.3-3.8,3.6-5.7C1315.4,665.8,1316.1,665.8,1316.9,665.9z"/>
          <path id="XMLID_39_" class="st0" d="M1395.7,657.6c-1.7,2.3-2.6,4.1-3.9,5.1c-6.2,4.6-6.5,12.3-0.6,16.9c1.7,1.4,3,3.3,5.2,5.9
            c1.1,0.2,3.4,0.8,3.3-3.1c0-0.7,1.1-1.5,1.9-2c7.7-5.7,7.7-12.4,0.1-18.1c-1.2-0.9-1.7-2.8-2.8-4.8c3.8-0.2,6.3-0.3,9.2-0.5
            c2.3-36.6,4.6-73,6.8-109.6c-12.2,0-23.6,0-35.6,0c2.3,36.7,4.6,73.1,6.9,109.6C1389.2,657.2,1391.7,657.4,1395.7,657.6z"/>
          <path id="XMLID_38_" class="st0" d="M1403.2,651.4c-1,0.3-1.3,0.6-1.6,0.6c-12.2,0.1-10.5,1.9-11.2-10.1
            c-1.8-29.6-3.7-59.1-5.6-89.1c8.2,0,16.2,0,24.6,0C1407.3,585.7,1405.2,618.5,1403.2,651.4z"/>
          <path id="XMLID_37_" class="st0" d="M1398.4,665.7c1.2,2,3.6,4.2,3.2,5.9c-0.3,1.8-3.2,3.2-5,4.7c-1.5-1.7-4-3.3-4.1-5.1
            c0-1.9,2.3-3.8,3.6-5.7C1396.9,665.6,1397.7,665.7,1398.4,665.7z"/>
          <path id="XMLID_42_" class="st0" d="M1481.2,685.6c1.6-2.2,2.5-4.1,4-5.3c6.6-5.1,6.6-12.4,0-17.5c-1.3-1-1.9-2.9-3.5-5.5
            c4.1,0,6.6,0,9,0c0.1-1.1,0.3-1.8,0.3-2.4c1.9-29.5,3.9-59.1,5.8-88.6c0.7-10.7-6.6-18.8-16.7-18.9c-10.3-0.1-17.5,8.1-16.9,19.1
            c1.3,21.4,2.6,42.8,4,64.2c0.6,8.8,1.3,17.5,1.9,26.5c2.8,0.2,5.2,0.3,9.4,0.6c-1.9,2.4-2.9,4.2-4.4,5.4c-5.7,4.5-5.8,12.2,0,16.6
            c1.1,0.9,2.8,1.9,2.9,3C1477.5,686.7,1479.8,685.8,1481.2,685.6z"/>
          <path id="XMLID_41_" class="st0" d="M1486,652c-4.1,0-7.7,0-11.9,0c-0.6-8.5-1.3-16.9-1.8-25.3c-1.3-20.6-2.6-41.2-3.7-61.8
            c-0.4-7,4.7-12.4,11.4-12.4c6.8,0,12,5.2,11.6,12.2c-1.2,21.1-2.6,42.2-3.9,63.3C1487.1,635.8,1486.6,643.6,1486,652z"/>
          <path id="XMLID_40_" class="st0" d="M1485.8,672.7c-2,1.3-4,3.6-5.9,3.5c-1.7-0.1-4.7-3.1-4.5-4.4c0.3-1.9,2.8-4.7,4.6-4.9
            c1.7-0.2,3.9,2.3,5.8,3.6C1485.8,671.2,1485.8,671.9,1485.8,672.7z"/>
          <path id="XMLID_51_" class="st0" d="M1560.4,657.7c-1.7,2.3-2.6,4.1-3.9,5.1c-6.2,4.6-6.5,12.3-0.6,16.9c1.7,1.4,3,3.3,5.2,5.9
            c1.1,0.2,3.4,0.8,3.3-3.1c0-0.7,1.1-1.5,1.9-2c7.7-5.7,7.7-12.4,0.1-18.1c-1.2-0.9-1.7-2.8-2.8-4.8c3.8-0.2,6.3-0.3,9.2-0.5
            c2.3-36.6,4.6-73,6.8-109.6c-12.2,0-23.6,0-35.6,0c2.3,36.7,4.6,73.1,6.9,109.6C1554,657.3,1556.5,657.4,1560.4,657.7z"/>
          <path id="XMLID_50_" class="st0" d="M1567.9,651.5c-1,0.3-1.3,0.6-1.6,0.6c-12.2,0.1-10.5,1.9-11.2-10.1
            c-1.8-29.6-3.7-59.1-5.6-89.1c8.2,0,16.2,0,24.6,0C1572.1,585.8,1570,618.6,1567.9,651.5z"/>
          <path id="XMLID_49_" class="st0" d="M1563.2,665.8c1.2,2,3.6,4.2,3.2,5.9c-0.3,1.8-3.2,3.2-5,4.7c-1.5-1.7-4-3.3-4.1-5.1
            c0-1.9,2.3-3.8,3.6-5.7C1561.6,665.7,1562.4,665.7,1563.2,665.8z"/>
          <path id="XMLID_48_" class="st0" d="M1643,657.5c-1.7,2.3-2.6,4.1-3.9,5.1c-6.2,4.6-6.5,12.3-0.6,16.9c1.7,1.4,3,3.3,5.2,5.9
            c1.1,0.2,3.4,0.8,3.3-3.1c0-0.7,1.1-1.5,1.9-2c7.7-5.7,7.7-12.4,0.1-18.1c-1.2-0.9-1.7-2.8-2.8-4.8c3.8-0.2,6.3-0.3,9.2-0.5
            c2.3-36.6,4.6-73,6.8-109.6c-12.2,0-23.6,0-35.6,0c2.3,36.7,4.6,73.1,6.9,109.6C1636.5,657.2,1639,657.3,1643,657.5z"/>
          <path id="XMLID_47_" class="st0" d="M1650.5,651.3c-1,0.3-1.3,0.6-1.6,0.6c-12.2,0.1-10.5,1.9-11.2-10.1
            c-1.8-29.6-3.7-59.1-5.6-89.1c8.2,0,16.2,0,24.6,0C1654.6,585.6,1652.5,618.4,1650.5,651.3z"/>
          <path id="XMLID_46_" class="st0" d="M1645.7,665.6c1.2,2,3.6,4.2,3.2,5.9c-0.3,1.8-3.2,3.2-5,4.7c-1.5-1.7-4-3.3-4.1-5.1
            c0-1.9,2.3-3.8,3.6-5.7C1644.2,665.6,1644.9,665.6,1645.7,665.6z"/>
          <path id="XMLID_56_" class="st0" d="M339.5,326.6"/>
          <line id="XMLID_45_" class="st0" x1="377.4" y1="326.6" x2="376.1" y2="326.6"/>
          <g id="beiYbO.tif">
            <g id="XMLID_43_">
              <path id="XMLID_120_" class="st0" d="M2288.7,1008.3c-12,0-23.9,0-35.9,0c-1.4-0.8-3-1.4-4.2-2.5c-3.1-2.8-3.9-6.6-3.9-10.8
                c-0.1-21.1-0.3-42.2-0.3-63.2c0-3.3,0-6.6,0.4-9.9c1.1-10.4,3.7-20.1,9.5-28.7c0.4-0.6,0.7-1.2,0.9-1.9c0.2-0.6,0.4-1.3,0.4-1.9
                c0.5-5.2,0.7-10.4,1-15.7c0.5-7.6,1-15.3,1.4-22.9c0-0.6-0.1-1.4-0.4-1.8c-1.6-1.8-1.7-3.9-1.6-6.2c0-0.9,0-2-0.4-2.8
                c-1.6-3.3-1.3-7.1,1-9.9c2.3-2.7,5.7-3.5,9-2c0.6,0.3,1.5,0.3,2.1,0c2.2-0.9,4.3-0.9,6.5,0c0.6,0.3,1.5,0.3,2,0
                c3.2-1.4,6.8-0.6,9,2c2.3,2.7,2.6,6.6,1,9.9c-0.4,0.8-0.6,1.9-0.5,2.8c0.1,2.1-0.1,4.1-1.3,5.9c-0.5,0.7-0.8,1.7-0.7,2.5
                c0.1,3.1,0.4,6.1,0.6,9.2c0.6,9.5,1.1,18.9,1.8,28.4c0.1,0.7,0.2,1.4,0.4,2.1c0.2,0.9,0.6,1.7,1.1,2.4c6.5,9.8,9.6,21,9.7,32.8
                c0.3,23.4-0.1,46.8-0.2,70.1c0,2.9-0.8,5.7-2.4,8.1C2293,1006.4,2290.9,1007.4,2288.7,1008.3z M2262.6,850.9
                c-0.8,13-1.6,25.8-2.4,38.8c4.1,0,7.9,0,11.8,0c0.7,0,1.6-0.1,2.1,0.3c0.7,0.5,1.5,1.4,1.5,2.2c0,0.8-0.8,1.7-1.4,2.2
                c-0.5,0.4-1.4,0.3-2.1,0.3c-4,0-7.9,0-11.9,0c-0.6,0-1.5,0.3-1.8,0.8c-1.5,2.4-3.1,4.7-4.2,7.3c-3.8,8.6-5.3,17.7-5.3,27.2
                c0.1,21,0.2,42,0.3,63.1c0,1.5,0.1,2.9,0.3,4.4c0.4,3.7,2.5,5.8,6,5.8c10.2,0,20.3,0,30.5,0c3.3,0,5.3-1.8,6-5.3
                c0.2-1.1,0.3-2.2,0.3-3.3c0.1-21.6,0.3-43.1,0.3-64.7c0-4-0.4-8.1-1-12.1c-1.2-7.6-3.3-14.8-7.6-21.2c-0.8-1.2-1.5-2.3-3.2-2.2
                c-1.1,0.1-2.1-0.8-2-2.1c0.1-0.9,0.6-2.2,1.3-2.5c1.1-0.5,1.2-1.2,1.1-2.1c-0.2-3.2-0.4-6.5-0.6-9.7c-0.6-8.9-1.1-17.7-1.7-26.6
                c0-0.4-0.4-0.7-0.6-1.1c-0.4-0.9-1.1-1.8-1.2-2.6c0-1.3,1-2,2.1-2c1.6,0,1.7-1.1,1.6-2.4c0-1.5-0.9-1.7-2-1.7c-3.5,0-7,0-10.4,0
                c-0.4,0-0.8,0-1.1,0.2c-2,1.7-4.2,2.2-6.7,1.9c-0.3,1.4,0.3,1.8,1.4,1.8c2.1,0,4.1,0,6.2,0c1,0,2.1,0,3.1,0.2
                c1.2,0.3,1.9,1.3,1.6,2.5c-0.2,0.8-1,1.6-1.8,2.2c-0.5,0.3-1.3,0.2-1.9,0.2C2267.1,850.9,2264.9,850.9,2262.6,850.9z M2282.1,837
                c0.6-1.3,0.3-2.8-0.8-3.8c-1.2-1.1-2.6-1.1-4,0.1c-2.1,1.7-2.4,1.7-4.5,0c-1.5-1.2-2.7-1.2-4.2,0.2c-1.7,1.5-2.3,1.4-4.1,0
                c-0.7-0.6-1.8-1-2.6-0.9c-1.3,0.1-2.3,1-2.5,2.5c-0.3,1.5,0.1,2.7,1.3,3.5c1.2,0.9,2.4,0.7,3.6-0.2c0.7-0.5,1.6-1.3,2.4-1.3
                C2271.7,836.9,2276.9,837,2282.1,837z"/>


              <path id="XMLID_96_" class="st0" d="M2287.5,949.2c-0.5,7.6-2,14.7-6.3,20.9c-2.8,4.1-6.4,6.9-11.4,6.5c-4.2-0.4-7.1-3-9.5-6.4
                c-4.6-6.7-6.2-14.4-6.1-22.6c0.1-6.9,1.4-13.4,4.8-19.3c1-1.7,2.2-3.4,3.6-4.8c5-5.1,11.3-5.1,16.3-0.1c4.6,4.7,6.8,10.8,7.8,17.4
                C2287.2,943.6,2287.3,946.4,2287.5,949.2z"/>
            </g>
          </g>
          <g id="beiYbO.tif_1_">
            <g id="XMLID_44_">
              <path id="XMLID_61_" class="st0" d="M2190.8,1010c-12,0-23.9,0-35.9,0c-1.4-0.8-3-1.4-4.2-2.5c-3.1-2.8-3.9-6.6-3.9-10.8
                c-0.1-21.1-0.3-42.2-0.3-63.2c0-3.3,0-6.6,0.4-9.9c1.1-10.4,3.7-20.1,9.5-28.7c0.4-0.6,0.7-1.2,0.9-1.9c0.2-0.6,0.4-1.3,0.4-1.9
                c0.5-5.2,0.7-10.4,1-15.7c0.5-7.6,1-15.3,1.4-22.9c0-0.6-0.1-1.4-0.4-1.8c-1.6-1.8-1.7-3.9-1.6-6.2c0-0.9,0-2-0.4-2.8
                c-1.6-3.3-1.3-7.1,1-9.9c2.3-2.7,5.7-3.5,9-2c0.6,0.3,1.5,0.3,2.1,0c2.2-0.9,4.3-0.9,6.5,0c0.6,0.3,1.5,0.3,2,0
                c3.2-1.4,6.8-0.6,9,2c2.3,2.7,2.6,6.6,1,9.9c-0.4,0.8-0.6,1.9-0.5,2.8c0.1,2.1-0.1,4.1-1.3,5.9c-0.5,0.7-0.8,1.7-0.7,2.5
                c0.1,3.1,0.4,6.1,0.6,9.2c0.6,9.5,1.1,18.9,1.8,28.4c0.1,0.7,0.2,1.4,0.4,2.1c0.2,0.9,0.6,1.7,1.1,2.4c6.5,9.8,9.6,21,9.7,32.8
                c0.3,23.4-0.1,46.8-0.2,70.1c0,2.9-0.8,5.7-2.4,8.1C2195,1008.1,2192.9,1009.1,2190.8,1010z M2164.7,852.6
                c-0.8,13-1.6,25.8-2.4,38.8c4.1,0,7.9,0,11.8,0c0.7,0,1.6-0.1,2.1,0.3c0.7,0.5,1.5,1.4,1.5,2.2c0,0.8-0.8,1.7-1.4,2.2
                c-0.5,0.4-1.4,0.3-2.1,0.3c-4,0-7.9,0-11.9,0c-0.6,0-1.5,0.3-1.8,0.8c-1.5,2.4-3.1,4.7-4.2,7.3c-3.8,8.6-5.3,17.7-5.3,27.2
                c0.1,21,0.2,42,0.3,63.1c0,1.5,0.1,2.9,0.3,4.4c0.4,3.7,2.5,5.8,6,5.8c10.2,0,20.3,0,30.5,0c3.3,0,5.3-1.8,6-5.3
                c0.2-1.1,0.3-2.2,0.3-3.3c0.1-21.6,0.3-43.1,0.3-64.7c0-4-0.4-8.1-1-12.1c-1.2-7.6-3.3-14.8-7.6-21.2c-0.8-1.2-1.5-2.3-3.2-2.2
                c-1.1,0.1-2.1-0.8-2-2.1c0.1-0.9,0.6-2.2,1.3-2.5c1.1-0.5,1.2-1.2,1.1-2.1c-0.2-3.2-0.4-6.5-0.6-9.7c-0.6-8.9-1.1-17.7-1.7-26.6
                c0-0.4-0.4-0.7-0.6-1.1c-0.4-0.9-1.1-1.8-1.2-2.6c0-1.3,1-2,2.1-2c1.6,0,1.7-1.1,1.6-2.4c0-1.5-0.9-1.7-2-1.7c-3.5,0-7,0-10.4,0
                c-0.4,0-0.8,0-1.1,0.2c-2,1.7-4.2,2.2-6.7,1.9c-0.3,1.4,0.3,1.8,1.4,1.8c2.1,0,4.1,0,6.2,0c1,0,2.1,0,3.1,0.2
                c1.2,0.3,1.9,1.3,1.6,2.5c-0.2,0.8-1,1.6-1.8,2.2c-0.5,0.3-1.3,0.2-1.9,0.2C2169.2,852.6,2167,852.6,2164.7,852.6z M2184.2,838.7
                c0.6-1.3,0.3-2.8-0.8-3.8c-1.2-1.1-2.6-1.1-4,0.1c-2.1,1.7-2.4,1.7-4.5,0c-1.5-1.2-2.7-1.2-4.2,0.2c-1.7,1.5-2.3,1.4-4.1,0
                c-0.7-0.6-1.8-1-2.6-0.9c-1.3,0.1-2.3,1-2.5,2.5c-0.3,1.5,0.1,2.7,1.3,3.5c1.2,0.9,2.4,0.7,3.6-0.2c0.7-0.5,1.6-1.3,2.4-1.3
                C2173.8,838.6,2178.9,838.7,2184.2,838.7z"/>

              <path id="XMLID_52_" class="st0" d="M2189.6,950.9c-0.5,7.6-2,14.7-6.3,20.9c-2.8,4.1-6.4,6.9-11.4,6.5c-4.2-0.4-7.1-3-9.5-6.4
                c-4.6-6.7-6.2-14.4-6.1-22.6c0.1-6.9,1.4-13.4,4.8-19.3c1-1.7,2.2-3.4,3.6-4.8c5-5.1,11.3-5.1,16.3-0.1c4.6,4.7,6.8,10.8,7.8,17.4
                C2189.2,945.3,2189.3,948.1,2189.6,950.9z"/>
            </g>
          </g>
          </svg>

       </div>
    )
  }
}
export default BarraSVG;
